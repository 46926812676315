import { Component, Input, OnInit } from '@angular/core';
import { IAppUser } from '@base';
import {Router} from "@angular/router";

@Component({
  selector: 'gds-shell-user',
  templateUrl: './shell-user.component.html',
  styleUrl: './shell-user.component.scss'
})
export class ShellUserComponent implements OnInit {
  @Input({ required: true }) user: IAppUser | null | undefined;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    if (this.user) {
      this.user.image = this.user.image ?? './assets/default_avatar.png';
    }
  }

  modifyUser() {
    this.router.navigate(['/user/' + this.user?.id])
  }
}
