import {Injectable} from '@angular/core';
import {FormDto} from "../../../../api/models/form-dto";
import {catchError, Observable, tap, throwError} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {FeedControllerService} from "../../../../api/services/feed-controller.service";
import {ToastService} from "../../../Base/services";

@Injectable()
export class ShellService {
  constructor(
      private feedControllerService: FeedControllerService,
      private translateService: TranslateService,
      private toastService: ToastService
  ) {
  }

  public createFeedEntry(formDto: FormDto): Observable<string> {
    return this.feedControllerService.createFeed({body: formDto}).pipe(
        tap(() => {
          this.toastService.showSuccessToast(this.translateService.instant('toast.newFeedEntrySuccess'));
        }),
        catchError((error) => {
          this.toastService.showErrorToast(this.translateService.instant('toast.newFeedEntryFailed'));
          return throwError(error);
        })
    );
  }

}
