import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BaseModule } from '@base';
import {
  LoginComponent,
  ShellAcesssComponent,
  ShellComponent,
  ShellMenuComponent,
  ShellUserComponent
} from '@shell/components';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
  declarations: [ShellComponent, ShellMenuComponent, ShellAcesssComponent, ShellUserComponent, LoginComponent],
  providers: [],
  imports: [BaseModule, RouterModule, MatToolbarModule]
})
export class ShellModule {}
