import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '@base';

@Component({
  selector: 'gds-shell',
  templateUrl: './shell.component.html',
  styleUrl: './shell.component.scss'
})
export class ShellComponent {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public authService: AuthService
  ) {
    this.registerIcon('gds-form', 'form.svg');
    this.registerIcon('gds-user', 'user.svg');
    this.registerIcon('gds-star', 'star.svg');
    this.registerIcon('gds-notification', 'notification.svg');
    this.registerIcon('gds-edit', 'edit.svg');
    this.registerIcon('gds-linkedin', 'linkedin.svg');
    this.registerIcon('gds-upload', 'upload.svg');
    this.registerIcon('gds-form-multiselect', 'multiselect.svg');
    this.registerIcon('gds-rating-star', 'ratingstar.svg');
    this.registerIcon('gds-type-number', '1.svg');
    this.registerIcon('gds-type-text', 'T.svg');
    this.registerIcon('gds-type-multiple-option', 'multipleoption.svg');
    this.registerIcon('gds-type-dropdown', 'dropdown.svg');
    this.registerIcon('gds-type-rate', 'starrate.svg');
    this.registerIcon('gds-trash', 'trash.svg');
    this.registerIcon('gds-arrow-up', 'arrow_up.svg');
    this.registerIcon('gds-arrow-down', 'arrow_down.svg');
    this.registerIcon('gds-feed-star', 'feedstar.svg');
    this.registerIcon('gds-feed-star-empty', 'feedstarempty.svg');
    this.registerIcon('gds-down', 'down.svg');
    this.registerIcon('gds-form-done', 'form_done.svg');
    this.registerIcon('gds-form-open', 'form_open.svg');
    this.registerIcon('gds-type-add', 'add.svg');
    this.registerIcon('gds-delete', 'delete.svg');
    this.registerIcon('gds-download', 'download.svg');
    this.registerIcon('gds-delete-feed', 'delete-feed.svg');
    this.registerIcon('gds-elca', 'elca.svg');

  }

  private registerIcon(label: string, path: string) {
    this.matIconRegistry.addSvgIcon(label, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/${path}`));
  }
}
