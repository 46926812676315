<div class="flex justify-center w-full">
  <mat-card class="w-1/2">
    <mat-card-content class="!flex flex-col gap-4 items-center">
      <h1 class="flex justify-center">{{ 'shell.login' | translate }}</h1>
      <button
        cdkFocusInitial
        mat-raised-button
        color="primary"
        class="w-fit !rounded-3xl"
        (click)="submit()"
      >
        {{ 'shell.loginTrustId' | translate }}
      </button>
    </mat-card-content>
  </mat-card>
</div>
