<div class="invisible md:visible w-0 h-0 md:w-auto md:h-auto flex gap-2">
  <button
    mat-flat-button
    class="!rounded-3xl"
    color="primary"
    (click)="createFeed()"
  >
    <mat-icon>add</mat-icon>
    {{ 'toolbar.action.newLogEntry' | translate }}
  </button>
</div>
<div class="visible md:invisible w-auto h-auto md:w-0 md:h-0 flex gap-2">
  <button
    mat-mini-fab
    class="!rounded-3xl"
    color="primary"
    (click)="createFeed()"
  >
    <mat-icon>add</mat-icon>
  </button>
</div>
