<div class="border-b border-graytone ">
  <mat-toolbar
          class="flex justify-between !m-auto max-w-[93rem]"
          color="primary"
  >
    <div class="flex items-center gap-4 h-full">
      <gds-logo
              routerLink="/"
              class="pr-0 md:pr-5 md:pl-5 w-1/4 md:w-auto"
      ></gds-logo>

      <div class="flex gap-4" *ngIf="!!(authService.user$ | async)?.admin">
        <a class="text-sm" routerLink="/">Logbook</a>
        <a class="text-sm" routerLink="/admin">Admin</a>
      </div>

      <gds-shell-menu *ngIf="!!(authService.user$ | async)"></gds-shell-menu>
    </div>

    <div class="flex items-center gap-2 h-full">
      <gds-shell-acesss *ngIf="!!(authService.user$ | async)"></gds-shell-acesss>
      <gds-shell-user
              *ngIf="!!(authService.user$ | async)"
              [user]="authService.user$ | async"
      ></gds-shell-user>
    </div>
  </mat-toolbar>
</div>
<div class="py-6 px-4 md:px-0 md:py-10 md:max-w-[93rem] m-auto">
  <router-outlet></router-outlet>
</div>
<footer class="bottom-0 px-4 py-1 items-start w-full fixed flex flex-col md:flex-row justify-between border-t border-graytone z-1 bg-primary-gray !h-10 md:!h-auto text-xs">
  <div>
    <div class="pb-1">
      {{ 'footer.title' | translate }}
    </div>
    <div>
      <div class="flex gap-1">
        <span class="text-[color:gray] text-xs">{{ 'footer.powered' | translate }}</span>
        <img [src]="'./assets/elca.png'" class=""/>
      </div>
    </div>
  </div>
  <div class="flex gap-4">
    <a href="https://gesundheitsdatenraum.ch/legal/impressum" target="_blank">{{ 'footer.print' | translate }}</a>
    <a href="https://gesundheitsdatenraum.ch/legal/datenschutz" target="_blank">{{ 'footer.dataProctection' | translate }}</a>
  </div>
</footer>
