import { Component, OnInit } from '@angular/core';
import { GdsErrorStateMatcher } from '../../../Base/models';
import { of, switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '@base';

@Component({
  selector: 'gds-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {

    if (this.authService.user) {
      this.router.navigate(['/']);
    }
  }

  submit() {
    this.authService
      .login('')
      .pipe(
        switchMap((isLoggendIn) => {
          if (isLoggendIn) {
            return this.authService.getCurrentUser();
          }
          return of(null);
        })
      )
      .subscribe((r) => {
        if (r) {
          this.router.navigate(['/admin']);
        }
      });
  }
}
