import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardAnonymousService } from '@base';
import { LoginComponent } from '@shell/components';

export const routes: Routes = [
  { path: '', redirectTo: 'feed', pathMatch: 'full' },
  {
    path: 'admin',
    loadChildren: () => import('@admin/admin.module').then((m) => m.AdminModule),
    canActivate: [() => inject(AuthGuardAnonymousService).canActivateAdmin()]  },
  {
    path: 'feed',
    loadChildren: () => import('@feed/feed.module').then((m) => m.FeedModule)
  },
  {
    path: 'user',
    loadChildren: () => import('@user/user.module').then((m) => m.UserModule)
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [() => inject(AuthGuardAnonymousService).canActivate()]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
