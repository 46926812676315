import {Component, OnInit} from '@angular/core';
import {Observable, switchMap, take} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {FeedEntryDialogComponent} from "../../../Feed/components/feed-entry-dialog/feed-entry-dialog.component";
import {FormDto} from "../../../../api/models/form-dto";
import {ShellService} from "../services/shell.service";
import {Router} from '@angular/router';

@Component({
    selector: 'gds-shell-acesss',
    templateUrl: './shell-acesss.component.html',
    styleUrl: './shell-acesss.component.scss',
    providers: [ShellService]
})
export class ShellAcesssComponent implements OnInit {

    constructor(
        private dialog: MatDialog,
        public shellService: ShellService,
        private router: Router) {

    }

    public createFeed() {
        this.openFormDialog().subscribe((result) => {
            if (result) {
                return this.shellService
                    .createFeedEntry(result)
                    .pipe(switchMap(() => this.router.navigate(['/feed'])))
                    .subscribe();
            }
            return;
        });
    }

    public openFormDialog(): Observable<FormDto> {
        const dialogRef = this.dialog.open(FeedEntryDialogComponent, {data: null});
        return dialogRef.afterClosed().pipe(take(1));
    }

    ngOnInit(): void {
        this.router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
        };
    }
}
