import {HashLocationStrategy, Location, LocationStrategy} from '@angular/common';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppRoutingModule} from './app.routes';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserModule, provideClientHydration} from '@angular/platform-browser';
import {ShellModule} from '@shell/shell.module';
import {ShellComponent} from '@shell/components';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ApiModule} from '@api/api.module';
import {Router} from '@angular/router';
import {catchError, of} from 'rxjs';
import {OAuthModule, OAuthService} from 'angular-oauth2-oidc';
import {AuthService, HttpAuthInterceptor} from '@base';
import {environment} from "../environments/environment";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MomentDateAdapter} from "@angular/material-moment-adapter";
import {FormlyMaterialModule} from "@ngx-formly/material";
import {FormlyMatFormFieldModule} from "@ngx-formly/material/form-field";
import {FormlyModule} from "@ngx-formly/core";

function HttpLoaderFactory(http: HttpClient, loc: Location): TranslateHttpLoader {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

const auththenticate = (authService: AuthService, router: Router) => {
    return () =>
        new Promise((resolve, reject) => {
            if (!authService.user) {
                authService
                    .getCurrentUser()
                    .pipe(
                        catchError(() => {
                            router.navigate(['/login']);
                            resolve('unauthenticated');
                            return of(false);
                        })
                    )
                    .subscribe((x) => {
                        if (!x) {
                            router.navigate(['/login']);
                            resolve('unauthenticated');
                        } else {
                            if (location.hash === '#/') {
                                router.navigate(['/feed']);
                            }
                            resolve(true);
                        }
                    });
            }
            resolve(true);
        });
};

const DATE_PICKER_FORMAT = {
    parse: {
        dateInput: 'DD/MM/yyyy',
    },
    display: {
        dateInput: 'DD/MM/yyyy',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    imports: [
        TranslateModule.forRoot({
            loader: {provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient, Location]},
            defaultLanguage: 'en'
        }),
        OAuthModule.forRoot(),
        HttpClientModule,
        environment.production ? ApiModule : ApiModule.forRoot({rootUrl: '/api'}),
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ShellModule,
        FormlyMaterialModule,
        FormlyMatFormFieldModule,
        FormlyModule.forRoot()
    ],
    providers: [
        provideClientHydration(),
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true},
        {provide: APP_INITIALIZER, useFactory: auththenticate, deps: [AuthService, Router, OAuthService], multi: true},
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: DATE_PICKER_FORMAT},
    ],
    bootstrap: [ShellComponent]
})
export class AppModule {
}
